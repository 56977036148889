/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Package
import React from 'react';
// Proptypes
import PropTypes from 'prop-types';
// UI local components
import Intro from './Sections-components/Intro';
import Body from './Sections-components/Body';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                 PageContent                                */
/* -------------------------------------------------------------------------- */
function PageContent({ dmnsDeMissionsData }) {
  const { sectionOne, sectionTwo, sectionThree } = dmnsDeMissionsData;

  return (
    <>
      <Intro ContentOne={sectionOne} ContentTwo={sectionThree} />
      <Body Data={sectionTwo} />
    </>
  );
}

export default PageContent;

PageContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dmnsDeMissionsData: PropTypes.object.isRequired,
};
