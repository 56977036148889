/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Package
import React from 'react';
// Proptypes
import PropTypes from 'prop-types';
// Style
import './index.scss';
import '../../../../shared/styles/global.scss';
// UI Local Component
import Final from './Final';

/* -------------------------------------------------------------------------- */
/*                                 PageContent                                */
/* -------------------------------------------------------------------------- */
function Intro({ ContentOne, ContentTwo }) {
  /* -------------------------------- Rendering ------------------------------- */
  return (
    <div className="section section-one">
      <p>{ContentOne.Intro}</p>
      <ul className="checkList">
        {ContentOne.list.map((item) => {
          return <li key={item.id}>{item.title}</li>;
        })}
      </ul>
      <p>
        Les missions font partie intégrante du programme-cadre Horizon Europe.
      </p>
      <br />
      <Final Data={ContentTwo} />
      <p>{ContentOne.body}</p>
      <ul className="checkList">
        <p>{ContentOne.paragraph}</p>
        {ContentOne.checkList.map((item) => {
          return <li key={item.id}>{item.title}</li>;
        })}
      </ul>
    </div>
  );
}

export default Intro;

Intro.propTypes = {
  ContentOne: PropTypes.objectOf(PropTypes.object).isRequired,
  ContentTwo: PropTypes.objectOf(PropTypes.object).isRequired,
};
