/* eslint-disable max-len */
const domainesDeMissions = {
  sectionOne: {
    Intro:
      'Une mission rassemble de multiples parties prenantes telles que des chercheurs, des entrepreneurs et les citoyens à travailler dans plusieurs disciplines et secteurs pour trouver des solutions aux grands défis dans lune des cinq zones de mission',
    list: [
      {
        id: 1,

        title:
          'Adaptation au changement climatique – transition vers une Europe résiliente et préparée au changement  climatique»',
      },
      {
        id: 2,

        title:
          'Santé des océans, des mers ainsi que des eaux côtières et  continentales – «Régénérer notre océan et nos eaux» ',
      },
      {
        id: 3,

        title: ' Cancer – «Vaincre le cancer : mission possible»',
      },
      {
        id: 4,

        title:
          'Villes intelligentes et neutres en carbone – «100 villes  climatiquement neutres dici 2030 - par et pour les citoyens»',
      },
      {
        id: 5,

        title:
          'Santé des sols et alimentation – «Prendre soin du sol, cest  prendre soin de la vie»',
      },
    ],
    body: 'Les missions dans chaque domaine sont proposées aux États membres par cinq comités de mission. Le budget de chaque mission dépend de l ampleur du défi qu`elle tente de résoudre. Toutefois, un maximum de 10 % du budget annuel du deuxième pilier sera alloué au financement de la mise en œuvre de missions au cours des trois premières années d Horizon Europe. Le calendrier de chaque mission dépend de l enjeu de la mission. Bien quil n y ait pas de calendrier spécifique une mission peut durent jusqu à 10 ans.',

    paragraph: 'Pour chaque mission, un comité de mission (« Mission Board »):',

    checkList: [
      {
        id: 2,
        title: 'Maximum de 15 personnalités indépendantes de haut niveau',
      },
      {
        id: 3,
        title: 'De toute l`Europe et au delà.',
      },
      {
        id: 4,
        title: 'Mandat de 5 ans maximum renouvelable une fois',
      },
    ],
  },
  sectionTwo: {
    Question: "À QUI S'ADRESSE UN SUJET DE MISSION ET QUE PEUT-ON FINANCER ?",
    paragraphOne: `Un thème de mission est pertinent pour les différentes parties prenantes du secteur public et privé car ainsi que pour les citoyens européens.
Une mission combinera potentiellement différents instruments de financement et actions politiques et sera probablement soutenu par une série d'activités, impliquant différents acteurs tels que le député
Les états, les citoyens européens et la Commission européenne (Commission).
Chaque mission peut comporter une ou plusieurs des actions suivantes :

`,
    checkListOne: [
      {
        id: 1,
        title:
          "Lancer des appels spécifiques dans le cadre d'Horizon Europe et d'autres programmes. Ces appels encouragent la créativité et les idées ascendantes à partir d'une proposition.",
      },
      {
        id: 2,
        title:
          ' Identifier des actions spécifiques pour changer/améliorer les contextes politiques, qui sont essentielles pour le succès des missions, comme les conditions-cadres.',
      },
      {
        id: 3,
        title: ' Faire appel à des partenariats pertinents.',
      },
      {
        id: 4,
        title:
          ' Mobiliser les fonds structurels pour les aligner sur les objectifs des missions.',
      },
      {
        id: 5,
        title:
          'Établir des liens appropriés avec les programmes et les politiques nationales.',
      },
      {
        id: 6,
        title:
          "Influencer l'agenda international, en combinant les efforts avec ceux de pays tiers similaires",
      },
    ],
    paragraphTwo:
      ' Pour chaque domaine de mission, le Comité de Programme est associé à la préparation et au cycle de vie des missions.',
    paragraphThree: ' Le programme de travail comprendra pour chaque mission:',
    checkListTwo: [
      {
        id: 1,
        title: 'Conception',
      },
      {
        id: 2,
        title: 'Caractéristiques du portefeuille de projets',
      },
      {
        id: 3,
        title: 'Dispositions spéciﬁques pour assurer une approche eﬃcace.',
      },
    ],
  },
  sectionThree: {
    paragraph: `Chaque mission fonctionnera comme un portefeuille d'actions - telles que des projets de recherche, des mesures politiques ou même des initiatives législatives - pour atteindre un objectif mesurable qui ne pourrait être atteint par des actions individuelles.
Les missions de l'UE contribueront aux objectifs du "Green Deal" européen, du plan "Europe's Beating Cancer" ainsi qu'aux objectifs de développement durable.
Les missions de l'UE :
`,
    list: [
      {
        id: 1,

        title:
          'audacieuses, inspirantes et largement pertinentes pour la société',
      },
      {
        id: 2,

        title:
          'clairement définies : ciblées, mesurables et limitées dans le temps',
      },
      {
        id: 3,

        title: "réalistes et axés sur l'impact",
      },
      {
        id: 4,

        title:
          'fédératrices des ressources aux niveaux régional (européen et pays associés), national et local',
      },
      {
        id: 5,

        title:
          "facilement adoptée par les citoyens, lequel saura saisir la valeur des investissements dans la recherche et l'innovation.",
      },
    ],
  },
};

export default domainesDeMissions;
