/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { Link, graphql } from 'gatsby';

// Lib UI components
import { Container, Visible } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/BannerSection';
import PageContent from '../page-components/domaines-de-messions/Page-content/index';

// Style
import '../page-styles/DomainesDeMissions.scss';
import '../shared/styles/global.scss';
// Assets
import BANNER_IMG from '../images/about-us-page/bannerAbout.jpg';
// Fake APi
import domainesDeMissions from '../shared/fake-APIs/domaines-de-missions';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function DomainesDeMissions() {
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Domaines de missions" />
      <Container className="domaines-de-missions fluid ">
        <BannerSection title="Domaines de missions" bannerImage={BANNER_IMG} />
        <Container fluid>
          <Container className="center">
            <Visible lg xl xxl md>
              <Container className="top flex items-center fluid">
                <Link className="breadcrumb__link" to="/">
                  Accueil
                </Link>
                <span className="m-r-l">/</span>
                <Link className="breadcrumb__link" to="/domaines-de-missions/">
                  Domaines de missions
                </Link>
              </Container>
            </Visible>
            <PageContent dmnsDeMissionsData={domainesDeMissions} />
          </Container>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["DomainesDeMissions", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default DomainesDeMissions;
