/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Package
import React from 'react';
// Proptypes
import PropTypes from 'prop-types';
// Style
import './index.scss';
import '../../../../shared/styles/global.scss';

/* -------------------------------------------------------------------------- */
/*                                 PageContent                                */
/* -------------------------------------------------------------------------- */
function Final({ Data }) {
  const { paragraph, list } = Data;
  return (
    <div className="section">
      <p>{paragraph}</p>
      <ul className="checkList">
        {list.map((item) => {
          return <li key={item.id}>{item.title}</li>;
        })}
      </ul>
    </div>
  );
}

export default Final;
Final.propTypes = {
  Data: PropTypes.objectOf(PropTypes.object).isRequired,
};
